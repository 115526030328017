import { ApiApi, Configuration } from '@/api'
import type { AxiosRequestConfig } from 'axios'
import router from '@/router'
import { errorHandler, throwError } from '@/services/ErrorHandler'
import { reactive } from 'vue'
import { type LocalUser } from '@/services/DataServices'
import { type AxiosResponse } from 'axios'
import { clearGlobalData } from '@/main'

export class CSAWFApi extends ApiApi {
  constructor(basePath?: string) {
    super(new Configuration(), basePath)
  }

  private static readonly sessionTokenName: string = 'csafi-api-token'
  private localUser = reactive({ userName: '', firstName: '', lastName: '' } as LocalUser)

  public login(username: string, password: string, token?: string, options?: AxiosRequestConfig) {
    return super
      .login(username, password, token, options) // Call super login method
      .then((response) => {
        if (response.status === 200 && response.data.token) {
          // Login successful, store token
          this.configuration = new Configuration({ accessToken: response.data.token })
          localStorage?.setItem(CSAWFApi.sessionTokenName, response.data.token)
        } else {
          throwError(response)
        }
        return response
      })
  }

  public axiosRequestConfigWithToken(token?: string): AxiosRequestConfig {
    // Returns new Axios HttpRequest object with required api token
    return <AxiosRequestConfig>{
      headers: { Authorization: `Bearer ${token || this.configuration?.accessToken}` }
    }
  }

  public async isLoggedIn(): Promise<boolean> {
    const token: string | null | undefined = localStorage.getItem(CSAWFApi.sessionTokenName) // Prevents 'can't be null' errors
    if (token) {
      this.configuration = this.configuration?.accessToken
        ? this.configuration
        : new Configuration({ accessToken: token })
      return await this.validateUserToken()
    }
    return false
  }

  public logout(then?: () => void): void {
    this.configuration = new Configuration()
    localStorage.removeItem(CSAWFApi.sessionTokenName)
    clearGlobalData()
    router.push({ path: '/login' }).then(() => {
      if (then) {
        then()
      }
    })
  }

  private async validateUserToken(): Promise<boolean> {
    return await new Promise<boolean>((r) => this.getProfileWithErrorHandling(() => r(true)))
  }

  public updateUser(loggedIn: boolean = true) {
    if (loggedIn) {
      this.getProfileWithErrorHandling((profile: AxiosResponse) => {
        const user: LocalUser = {
          userName: profile.data.current_user.username || '',
          firstName: profile.data.current_user.first_name || '',
          lastName: profile.data.current_user.last_name || '',
          email: profile.data.current_user.email || '',
          initials: (
            (profile.data.current_user.first_name.charAt(0) || '') +
            (profile.data.current_user.last_name.charAt(0) || '')
          ).toUpperCase()
        }
        for (const userKey in user) {
          this.localUser[userKey as keyof LocalUser] = user[userKey as keyof LocalUser] || ''
        }
      })
    }
  }

  private getProfileWithErrorHandling(then: Function) {
    this.getProfile(1, 0, undefined, undefined, this.axiosRequestConfigWithToken())
      .then((profile) => then(profile))
      .catch((e) => errorHandler(e, true))
  }

  public getUser(): LocalUser {
    return this.localUser
  }

  public getBackendUrl(): string {
    return this.basePath
  }

  public getBackendAdminUrl(): string {
    return this.getBackendUrl() + '/admin/'
  }
}

const url = import.meta.env.PROD ? window.location.origin : import.meta.env.VITE_BACKEND_URL
const csawfApi = new CSAWFApi(url)

export default csawfApi
