<template>
  <div data-testid="count-pip-test-id" class="pip-wrapper pointer-events-none">
    <div class="pip-text absolute flex items-center justify-center font-bold text-white">
      {{ countString }}
      <div class="pip pointer-events-none"></div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from 'vue'

enum cornerEnum {
  TOPLEFT = 'TOPLEFT',
  TOPRIGHT = 'TOPRIGHT',
  BOTTOMLEFT = 'BOTTOMLEFT',
  BOTTOMRIGHT = 'BOTTOMRIGHT'
}

type corner =
  | cornerEnum.TOPLEFT
  | cornerEnum.TOPRIGHT
  | cornerEnum.BOTTOMLEFT
  | cornerEnum.BOTTOMRIGHT

export default defineComponent({
  name: 'CountPipComponent',
  computed: {
    countString(): string {
      const count: number | undefined = this.$props.count
      if (!count) return ''
      const countStr = count > this.maxCount ? this.maxCount.toString() + '+' : count.toString()
      return (this.displayMultiply ? '' : '') + countStr
    },
    offsetYString(): string {
      return this.offsetY.toString() + 'rem'
    },
    offsetXString(): string {
      return this.offsetX.toString() + 'rem'
    },
    wrapperLeft(): string {
      return this.corner.includes('RIGHT') ? '100%' : '0'
    },
    wrapperBottom(): string {
      return this.corner.includes('TOP') ? '100%' : '0'
    }
  },
  props: {
    count: {
      type: Number,
      required: true
    },
    displayMultiply: {
      type: Boolean,
      default: true
    },
    corner: {
      type: String as PropType<corner>,
      default: cornerEnum.TOPRIGHT
    },
    offsetY: {
      type: Number,
      default: -0.1
    },
    offsetX: {
      type: Number,
      default: 0.5
    },
    maxCount: {
      type: Number,
      default: 999
    }
  }
})
</script>

<style scoped>
.pip-wrapper {
  position: relative;
  width: 0;
  height: 0;
  display: flex;
  --left: v-bind(wrapperLeft);
  left: var(--left);
  --bottom: v-bind(wrapperBottom);
  bottom: var(--bottom);
  z-index: 1;
}

.pip-text {
  --offset-y: v-bind(offsetYString);
  --offset-x: v-bind(offsetXString);
  left: var(--offset-x);
  top: var(--offset-y);
  font-size: 0.75rem;
  text-shadow: -1px 1px 1px rgb(0 0 0 / 33%);
}

.pip,
.pip::before,
.pip::after {
  height: 1rem;
  position: absolute;
}

.pip::before,
.pip::after {
  width: 0.5rem;
  height: 100%;
  top: 0;
  content: '';
}

.pip {
  --radius-offset: 84%;
  --linear-offset-start: 8%;
  --linear-offset-end: 92%;
  --border-radius: 0.5rem;
  --negative-border-radius: calc(var(--border-radius) * -1);
  --background-colour: rgb(10, 145, 180);
  --transition-colour: rgba(0, 0, 0, 0);
  width: calc(100% - 0.33rem);
  z-index: -1;
  opacity: 0.9;
  filter: drop-shadow(-0.075rem 0.175rem 0.05rem #00000085);
  background-image: linear-gradient(
    var(--transition-colour),
    var(--background-colour) var(--linear-offset-start),
    var(--background-colour) var(--linear-offset-end),
    var(--transition-colour)
  );
  text-align: center;
}

.pip::before {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  left: var(--negative-border-radius);
  background-image: radial-gradient(
    circle var(--border-radius) at var(--border-radius),
    var(--background-colour) var(--radius-offset),
    var(--transition-colour)
  );
}

.pip::after {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  right: var(--negative-border-radius);
  background-image: radial-gradient(
    circle var(--border-radius) at 0,
    var(--background-colour) var(--radius-offset),
    var(--transition-colour)
  );
}
</style>
